import React from "react";
import styled from "styled-components";

const PageHeader = (props) => {
  const { title, paragraph, dark } = props;
  if (dark) {
    return (
      <Holder className="title">
        <h2 style={{ color: "var(--clr-white)" }}>{title}</h2>
        <p style={{ color: "var(--clr-grey-8)" }}>{paragraph}</p>
        <div className="underline"></div>
      </Holder>
    );
  } else {
    return (
      <Holder className="title">
        <h2>{title}</h2>
        <p>{paragraph}</p>
        <div className="underline"></div>
      </Holder>
    );
  }
};

const Holder = styled.section`
  /*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v7.0.29,
* Autoprefixer: v9.7.6
* Browsers: last 4 version
*/

  padding-top: 1rem;
  h2 {
    font-family: "Poppins", sans-serif;
    font-size: 2.25rem;
    letter-spacing: 0;
    line-height: 1.25;
    color: var(--clr-blue1);
  }
  p {
    line-height: 1.5;
    font-size: 1.05rem;
    color: var(--clr-grey-1);
  }
  @media screen and (min-width: 800px) {
    h2 {
      font-size: 2.5rem;
    }
    p {
      font-size: 1.25rem;
    }
  }
`;

export default PageHeader;
