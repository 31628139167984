export const links = [
  {
    id: 1,
    text: "Inicio",
    url: "/",
  },
  {
    id: 2,
    text: "Nuestra Empresa",
    url: "/#about",
    smooth: true,
  },
  {
    id: 3,
    text: "Servicios",
    url: "/#services",
    smooth: true,
  },
  {
    id: 4,
    text: "Clientes",
    url: "/#clients",
    smooth: true,
  },
  {
    id: 5,
    text: "Obras",
    url: "/works",
  },
  {
    id: 6,
    text: "Contacto",
    url: "/contact",
  },
];

export const services = [
  {
    id: 1,
    imgSrc: "/assets/services/Alquiler-de-Grúas.jpg",
    title: "Alquiler de Grúas",
    paragraph: "Alquilamos grúas para todo tipo de trabajos",
  },
  {
    id: 2,
    imgSrc: "../assets/services/Industrias.jpg",
    title: "Industrias",
    paragraph: "Mejoramos sus industrias",
  },
];
