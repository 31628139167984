import React from "react";
import { Carousel } from "react-bootstrap";

import styled from "styled-components";

const CarouselServices = (props) => {
  const { services } = props;
  /* <div key={banner.id}>
            <img src={banner.source} alt={banner.title} />
            <p className="legend">{banner.title}</p>
          </div> */
  return (
    <Wrapper>
      <Carousel
        indicators={false}
        pause="hover"
        interval={5000}
        className="first"
      >
        {services.map((banner) => {
          return (
            <Carousel.Item className="second" key={banner.s_id}>
              <img
                src={`data:image/${banner.s_img_ext};base64,${banner.s_img_data}`}
                alt={banner.s_title}
              />
              <Carousel.Caption className="third">
                <h3 className="title">{banner.s_title}</h3>
                <p className="paragraph">{banner.s_description}</p>
              </Carousel.Caption>
            </Carousel.Item>
          );
        })}
      </Carousel>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  /*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v7.0.29,
* Autoprefixer: v9.7.6
* Browsers: last 4 version
*/

  height: 25rem;
  position: relative;
  margin-top: 1rem;
  // width: 80vw;
  // margin-left: 10vw;
  .carousel-control-prev,
  .carousel-control-next {
    z-index: 40;
    opacity: 1;
    span {
      // -webkit-box-shadow: var(--light-shadow);
      // box-shadow: var(--light-shadow);

      //padding: 5rem;
      user-select: none;
      transition: var(--transition);
    }
    &:hover {
      //background-color: var(--clr-grey-6);
      opacity: 0.8;
    }
  }

  .first {
    // position: absolute;
    height: 100%;
    width: 100%;

    .second {
      // background-color: var(--clr-grey-4);
      text-align: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      height: 25rem;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        -o-object-fit: cover;
        -o-object-position: center;
        object-position: center;
      }
      .third {
        // width: 70%;
        width: 100%;
        margin-left: -15%;
        background-color: var(--clr-blue3);
        margin-bottom: -1.4rem;
        padding-left: 10px;
        padding-right: 10px;
        .title {
          color: var(--clr-grey-10);
          font-family: sans-serif;
          letter-spacing: 0;
          font-size: 1.25rem;
          line-height: 2;
          text-transform: none;
          font-family: "Poppins", sans-serif;
        }
        .paragraph {
          color: var(--clr-grey-8);
          text-transform: none;
          font-size: 1.2rem;
        }
      }
    }
  }
  @media screen and (min-width: 768px) {
    height: 30rem;
    width: 100%;
    max-width: 768px;
    .first {
      width: 100%;
      height: 30rem;
      .second {
        height: 30rem;
      }
    }
  }
  @media screen and (min-width: 992px) {
    height: 30rem;
    width: 100%;
    max-width: 768px;
    .first {
      width: 100%;
      height: 30rem;
      .second {
        height: 30rem;
      }
    }
  }
`;

export default CarouselServices;
