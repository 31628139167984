import React from "react";
import styled from "styled-components";
import { FaMapMarkerAlt, FaWhatsapp } from "react-icons/fa";

import { FloatingButtonsData } from "../utils/floatingButtonsData";

const FloatingButtons = () => {
  return (
    <Wrapper>
      {FloatingButtonsData.map((flbtn) => {
        return <FButton key={flbtn.id} {...flbtn} />;
      })}
    </Wrapper>
  );
};

const FButton = (props) => {
  const { link, icon, title, stackPos } = props;
  function openInNewTab(url) {
    var win = window.open(url, "_blank");
    win.focus();
  }
  return (
    <a
      className="floatingButton"
      data-toggle="tooltip"
      data-placement="left"
      title={title}
      rel="noopener noreferrer"
      style={{
        bottom: 10 + 70 * stackPos + "px",
      }}
      onClick={() => openInNewTab(link)}
    >
      {icon === "fa fa-map-marker" ? (
        <FaMapMarkerAlt className="flBtnIcon" />
      ) : (
        <FaWhatsapp className="flBtnIcon" />
      )}
    </a>
  );
};

const Wrapper = styled.div`
  /*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v7.0.29,
* Autoprefixer: v9.7.6
* Browsers: last 4 version
*/

  .floatingButton {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 10px;
    right: 10px;
    background-color: var(--clr-green1);
    color: var(--clr-white);
    border-radius: 50px;
    text-align: center;
    -webkit-box-shadow: 2px 2px 3px rgba(153, 153, 153, 0.651);
    box-shadow: 2px 2px 3px rgba(153, 153, 153, 0.651);
    z-index: 31;
    &:hover {
      text-decoration: none;
      color: #fff;
      cursor: pointer;
    }
  }
  .flBtnIcon {
    font-size: 30px;
    margin-top: 15px;
  }
`;

export default FloatingButtons;
