import React from "react";
import styled from "styled-components";
import { FiCheckCircle, FiInfo, FiTool } from "react-icons/fi";

import { PageHeader } from "../components";

const About = () => {
  return (
    <Wrapper className="section" id="about">
      <PageHeader title="¿Quiénes Somos?" dark={false} />
      <div className="services-center">
        <article className="service">
          <span className="icon">
            <FiInfo />
          </span>
          <h4>Historia</h4>
          <p>
            Somos una empresa fundada en Marzo de 2004 y nos encargamos de
            ofrecer servicios técnicos en los sectores eléctricos,
            electromecánicos, control industrial, naval y de telecomunicaciones;
            con provisión e instalación de equipos y afines.
          </p>
        </article>
        <article className="service">
          <span className="icon">
            <FiTool />
          </span>
          <h4>Servicios</h4>
          <p>
            Realizamos servicios de Instalaciones Electromecánicas, Montajes,
            Automatización, Proyectos de Ingeniería, Mantenimiento Preventivo y
            Correctivo relacionados, que se distinguen en el mercado por su
            calidad, eficacia y eficiencia.
          </p>
        </article>
        <article className="service">
          <span className="icon">
            <FiCheckCircle />
          </span>
          <h4>Política de Calidad</h4>
          <p>
            Nos comprometemos a trabajar con equipos y materiales adecuados,
            personal capacitado y comprometido con el cumplimiento de los
            requisitos de los clientes.
          </p>
        </article>
      </div>
    </Wrapper>
  );
};
const Wrapper = styled.section`
  /*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v7.0.29,
* Autoprefixer: v9.7.6
* Browsers: last 4 version
*/

  h4 {
    color: var(--clr-blue1);
    letter-spacing: 0;
    font-family: "Poppins", sans-serif;
  }
  padding: 5rem 15px;
  .services-center {
    margin-top: 4rem;
    display: -ms-grid;
    display: grid;
    gap: 1.5rem;
  }
  .service {
    background: var(--clr-grey-10);
    text-align: center;
    padding: 2.5rem 2rem;
    //border: 4px var(--clr-blue1) solid;
    border-radius: var(--radius);
    -webkit-box-shadow: var(--light-shadow);
    box-shadow: var(--light-shadow);

    p {
      color: var(--clr-grey-2);
      line-height: 1.75;
      font-size: 1rem;
      font-weight: 400;
    }
  }
  span {
    width: 4rem;
    height: 4rem;
    display: -ms-grid;
    display: grid;
    margin: 0 auto;
    place-items: center;
    margin-bottom: 1rem;
    border-radius: 9999px;
    background: var(--clr-grey-9);
    color: var(--clr-green1);
    svg {
      font-size: 2rem;
    }
  }
  @media (min-width: 576px) {
    margin: 0 30px;
    .services-center {
      grid-template-columns: repeat(auto-fit, minmax(360px, 1fr));
    }
    .service {
      p {
        padding-top: 0.5rem;
        font-size: 1.1rem;
      }
    }
  }
  /* @media (min-width: 1280px) {
    padding: 0;
    .section-center {
      transform: translateY(5rem);
    }
  } */
`;

export default About;
