import { React, useEffect, useState } from "react";
import { routeWorks } from "../utils/db";

import { Navbar, PageHeader } from "../components";
import { IoMdCloseCircleOutline } from "react-icons/io";
import { FiChevronRight, FiChevronLeft } from "react-icons/fi";
import { GiClick } from "react-icons/gi";

import styled from "styled-components";
import { useLoadingCallback } from "../hooks/useLoadingCallback";

import loadingSpinner from "../assets/Eclipse-1s-200px - loading.svg";

const WorksPage = () => {
  const [works, setWorks] = useState([]);
  const [fetchWorks, isLoading, error, reset] = useLoadingCallback(async () => {
    const response = await fetch(
      `${process.env.REACT_APP_WS_BASE_URL}/${routeWorks}`
    );
    const jsonData = await response.json();
    setWorks(jsonData);
  });

  useEffect(() => {
    fetchWorks();
  }, []);
  useEffect(() => {
    document.title = "Intech SRL - Obras";
  });
  return (
    <>
      <Navbar activeLink="Obras" />
      <Wrapper style={{ backgroundColor: "var(--clr-grey-9)" }}>
        <PageHeader
          title="Obras"
          paragraph="Con una gran experiencia en distintos rubros, ponemos a tu disposición trabajos que hemos realizado."
          dark={false}
        />
        <Container error={error} isLoading={isLoading} works={works} />
      </Wrapper>
    </>
  );
};

const Container = (props) => {
  const { error, isLoading, works } = props;
  if (error) {
    return (
      <span
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "70vh",
        }}
      >
        <h4 className="error-message-dark">{error.message}</h4>
      </span>
    );
  }
  if (isLoading || !works) {
    return (
      <span
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "70vh",
        }}
      >
        <img src={loadingSpinner} draggable={false} />
      </span>
    );
  }
  return (
    <>
      <div className="work-list">
        {works.map((work) => {
          return <Work2 key={work.w_id} {...work} />;
        })}
      </div>
    </>
  );
};

const Work2 = (props) => {
  const {
    w_id,
    w_title,
    w_description,
    w_classification,
    wi_extension,
    wi_data,
  } = props;
  const [images, setImages] = useState([]);
  /* useEffect(() => {
    getImagesOfWork();
  }, []); */

  const [fetchImagesOfWork, isLoading, error, reset] = useLoadingCallback(
    async () => {
      const response = await fetch(
        `${process.env.REACT_APP_WS_BASE_URL}/${routeWorks}/${w_id}`
      );
      const jsonData = await response.json();
      setImages(jsonData);
    }
  );
  return (
    <>
      <article
        className="work"
        data-toggle="modal"
        href={`#modal-${w_id}`}
        onClick={() => {
          if (images.length > 0) {
          } else {
            fetchImagesOfWork();
          }
        }}
      >
        <span className="clickable-container">
          <GiClick className="clickable-icon" />
        </span>
        <img
          src={`data:image/${wi_extension};base64,${wi_data}`}
          alt={`${w_title}-cover`}
        />
        <h5>{w_title}</h5>
        <p className="description">{w_description}</p>
        <p className="clasification">{w_classification}</p>
      </article>
      <WorkModal
        images={images}
        w_id={w_id}
        imagesLength={images.length}
        isLoading={isLoading}
        error={error}
      />
    </>
  );
};

const WorkModal = (props) => {
  const { images, w_id, imagesLength, isLoading, error } = props;
  const [currentImg, setCurrentImg] = useState(0);
  const nextImg = () => {
    if (currentImg !== images.length - 1) setCurrentImg(currentImg + 1);
    else setCurrentImg(0);
  };
  const prevImg = () => {
    if (currentImg !== 0) setCurrentImg(currentImg - 1);
    else setCurrentImg(images.length - 1);
  };
  const setImg = (index) => {
    setCurrentImg(index);
  };
  useEffect(() => {}, [currentImg]);
  return (
    <div className="modal" id={`modal-${w_id}`}>
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <ImagesOfWorkContainer
            error={error}
            isLoading={isLoading}
            images={images}
            prevImg={prevImg}
            nextImg={nextImg}
            imagesLength={imagesLength}
            currentImg={currentImg}
            setImg={setImg}
          />
        </div>
      </div>
    </div>
  );
};

const ImagesOfWorkContainer = (props) => {
  const {
    error,
    isLoading,
    images,
    prevImg,
    nextImg,
    imagesLength,
    currentImg,
    setImg,
  } = props;
  if (error) {
    return (
      <span
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "70vh",
        }}
      >
        <h4 className="error-message-dark">{error.message}</h4>
      </span>
    );
  }
  if (isLoading || !images) {
    return (
      <span
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "30em",
        }}
      >
        <img src={loadingSpinner} draggable={false} />
      </span>
    );
  }

  return (
    <>
      <div className="modal-body">
        <button className="close fa fa-close" data-dismiss="modal">
          <IoMdCloseCircleOutline />
        </button>
        <button className="prevImage" onClick={prevImg}>
          <FiChevronLeft />
        </button>
        <button className="nextImage" onClick={nextImg}>
          <FiChevronRight />
        </button>
        {imagesLength > 0 && (
          <img
            src={`data:image/${images[currentImg].wi_extension};base64,${images[currentImg].wi_data}`}
            alt={images[currentImg].wi_id}
          />
        )}
      </div>
      <div className="modal-footer">
        {images.map((image, index) => {
          if (index === currentImg) {
            return (
              <div
                key={index}
                className="thumbnail active"
                onClick={() => setImg(index)}
              >
                <img
                  src={`data:image/${image.wi_extension};base64,${image.wi_data}`}
                  alt={index}
                />
              </div>
            );
          } else {
            return (
              <div
                key={index}
                className="thumbnail"
                onClick={() => setImg(index)}
              >
                <img
                  src={`data:image/${image.wi_extension};base64,${image.wi_data}`}
                  alt={index}
                />
              </div>
            );
          }
        })}
      </div>
    </>
  );
};

const Wrapper = styled.section`
  /*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v7.0.29,
* Autoprefixer: v9.7.6
* Browsers: last 4 version
*/

  .work-list {
    display: -ms-grid;
    display: grid;
    gap: 2rem;
    margin: 1rem auto 0 auto;
    padding: 0 1rem 2rem 1rem;
    .work {
      border-radius: var(--radius);
      //padding: 1rem 2rem;
      text-align: center;
      min-height: 21rem;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      text-decoration: none;
      background-color: var(--clr-grey-10);
      -webkit-transition: var(--transition);
      -o-transition: var(--transition);
      transition: var(--transition);
      -webkit-box-shadow: var(--light-shadow);
      box-shadow: var(--light-shadow);

      .clickable-container {
        position: absolute;
        rigth: 20px;
        margin: 10px;
        border-radius: 50px;
        background: var(--clr-grey-9);
        color: var(--clr-blue1);
        padding: 7px;
      }
      .clickable-icon {
        font-size: 1.5em;
        filter: box-shadow(
          rgba(0, 0, 0, 0.9) 0px 0px 5px 0px,
          rgba(0, 0, 0, 0.9) 0px 0px 1px 0px
        );
      }
      img {
        width: 100%;
        height: 15rem;
        -o-object-fit: cover;
        object-fit: cover;
      }
      h5 {
        font-family: "Poppins", sans-serif;
        font-weight: bold;
        letter-spacing: 0;
        color: var(--clr-blue1);
        margin: 0.5rem 1rem 0.5rem 1rem;
        font-size: 1.3rem;
      }
      .description {
        color: var(--clr-grey-4);
        margin: 0 1rem 0 1rem;
        font-size: 1rem;
      }
      .clasification {
        color: var(--clr-green1);
        font-size: 0.8rem;
        margin-top: 1rem;
        text-transform: uppercase;
        font-weight: bold;
      }
      &:hover {
        -webkit-box-shadow: var(--dark-shadow);
        box-shadow: var(--dark-shadow);
        cursor: pointer;
      }
    }
    .modal {
      min-height: 35rem;
      .modal-dialog {
        .modal-content {
          .modal-body {
            padding: 0;
            //max-width: 65vw;
            .close {
              z-index: 29;
              position: absolute;
              font-size: 2.5rem;
              top: 10px;
              right: 10px;
              color: var(--clr-grey-8);
              -webkit-transition: var(--transition);
              -o-transition: var(--transition);
              transition: var(--transition);
              &:hover {
                color: var(--clr-grey-4);
              }
            }
            .prevImage,
            .nextImage {
              z-index: 30;
              cursor: pointer;
              position: absolute;
              margin: 0;
              top: 50%;
              width: auto;
              padding: 0.15rem 0.05rem 0.35rem 0.05rem;
              -ms-transform: translateY(-50%);
              -webkit-transform: translateY(-50%);
              transform: translateY(-50%);
              color: var(--clr-blue1);
              font-weight: bold;
              font-size: 2rem;
              -moz-user-select: none;
              -ms-user-select: none;
              user-select: none;
              -webkit-user-select: none;
              background-color: var(--clr-grey-10);
              border: 0;
              -webkit-transition: var(--transition);
              -o-transition: var(--transition);
              transition: var(--transition);

              &:hover {
                background-color: var(--clr-grey-8);
              }
            }
            .prevImage {
              left: 0;
            }
            .nextImage {
              right: 0;
            }

            img {
              width: 100%;
              height: 100%;
              //max-width: 65vw;
              //max-height: 80vh;
              //width: auto;
              //height: auto;
              object-fit: contain;
              -o-object-fit: contain;
              text-align: center !important;
            }
          }
          .modal-footer {
            height: auto;
            padding: 0;
            overflow-x: auto;
            overflow-y: hidden;
            white-space: nowrap;
            display: block !important;
            text-align: center;
            .thumbnail {
              margin: 0;
              opacity: 0.6;
              display: inline-block !important;
              -webkit-transition: all 0.05s ease-in;
              -o-transition: all 0.05s ease-in;
              transition: all 0.05s ease-in;
              &:hover {
                opacity: 1;
                cursor: pointer;
                -webkit-transition: all 0.05s ease-in;
                -o-transition: all 0.05s ease-in;
                transition: all 0.05s ease-in;
              }
              img {
                -o-object-fit: contain;
                object-fit: contain;
                max-width: 100%;
                max-height: 100%;
              }
            }
            .active {
              opacity: 1;
            }
          }
        }
      }
    }
  }
  @media screen and (min-width: 768px) {
    .work-list {
      margin-bottom: 0;
      padding: 0 3rem 2rem 3rem;
      -ms-grid-columns: (1fr) [3];
      grid-template-columns: repeat(3, 1fr);
      .work {
        img {
          /* width: 100%;
          height: 100%;
          max-width: 15rem;
          max-height: 15rem;
          width: auto;
          height: auto; */
        }
      }
    }
    .modal {
      .modal-dialog {
        .modal-content {
          min-height: 40rem;
          .modal-body {
            position: relative;
            img {
              display: block;
              margin-left: auto;
              margin-right: auto;
              position: absolute;
              top: 50%;
              -ms-transform: translateY(-50%);
              -webkit-transform: translateY(-50%);
              transform: translateY(-50%);
            }
          }
          .modal-footer {
            .thumbnail {
              padding-left: 0.6px;
              padding-right: 0.6px;
              width: 5vw;
              max-height: 14vh;
            }
          }
        }
      }
    }
  }
  @media screen and (min-width: 768px) and (max-width: 1200px) {
    .work-list {
      -ms-grid-columns: (1fr) [2];
      grid-template-columns: repeat(2, 1fr);
    }
  }
  @media screen and (max-width: 768px) {
    .modal {
      margin-top: 5rem;
      .modal-dialog {
        .modal-content {
          min-height: 28rem;
          .modal-body {
            position: relative;
            img {
              display: block;
              margin-left: auto;
              margin-right: auto;
              position: absolute;
              top: 50%;
              -ms-transform: translateY(-50%);
              -webkit-transform: translateY(-50%);
              transform: translateY(-50%);
              width: 100%;
              max-width: 100vw;
            }
          }
          .modal-footer {
            .thumbnail {
              padding-left: 0.6px;
              padding-right: 0.6px;
              max-height: 3rem;
              max-width: 3rem;
              //width: 5vw;
              img {
                /* max-width: 100%;
                max-height: 100%; */
                height: auto;
                width: auto;
              }
            }
          }
        }
      }
    }
  }
`;

export default WorksPage;
