export const FloatingButtonsData = [
  {
    id: "map-marker",
    link: "https://www.google.com/maps/place/INTECH+S.R.L./@-25.303609,-57.5520184,15z/data=!4m2!3m1!1s0x0:0x281730cc302716b1?sa=X&ved=2ahUKEwiW-IjxouHrAhWdGbkGHcGGAYQQ_BIwCnoECBYQCA",
    icon: "fa fa-map-marker",
    title: "Intech SRL en Maps",
    stackPos: 0,
  },
  {
    id: "whatsapp",
    link: `https://wa.me/${process.env.REACT_APP_SOCIAL_WHATSAPP_CENTRAL}`,
    icon: "fa fa-whatsapp",
    title: "Contacto por WhatsApp\n(Central)",
    stackPos: 1,
  },
  {
    id: "whatsapp",
    link: `https://wa.me/${process.env.REACT_APP_SOCIAL_WHATSAPP_ALTOPARANA}`,
    icon: "fa fa-whatsapp",
    title: "Contacto por WhatsApp\n(Alto Paraná)",
    stackPos: 2,
  },
];
