import React, { useState, useEffect } from "react";
import styled from "styled-components";
import logo_cables from "../assets/logo-cables.png";
import logo from "../assets/logo.png";
import { FaBars } from "react-icons/fa";
import { Link } from "react-router-dom";
import { links } from "../utils/constants";
import { HashLink } from "react-router-hash-link";

const Navbar = ({ activeLink }) => {
  const [showLinks, setShowLinks] = useState(true);
  const toggleLinks = () => {
    setShowLinks(!showLinks);
  };
  const [width, setWidth] = useState(window.innerWidth);
  const checkSize = () => {
    setWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", checkSize);
    if (width < 992) setShowLinks(false);
    else setShowLinks(true);
    return () => {
      window.removeEventListener("resize", checkSize);
    };
  }, [width]);

  return (
    <NavContainer>
      <div className="nav-center">
        {width < 992 && (
          <div className="nav-header">
            <button type="button" className="nav-toggle" onClick={toggleLinks}>
              <FaBars />
            </button>
            <Link to="/">
              <img src={logo} alt="intech logo" />
            </Link>
          </div>
        )}
        {showLinks && (
          <div
            className={`${
              showLinks ? "links-container show-container" : "links-container"
            }`}
          >
            <ul className="nav-links">
              {links.map((link) => {
                const { id, text, url, smooth } = link;
                return (
                  <li key={id}>
                    <HashLink
                      to={url}
                      className={activeLink === text ? "activeLink" : ""}
                      smooth={smooth ? true : false}
                      style={{ textTransform: "none" }}
                    >
                      {text}
                    </HashLink>
                  </li>
                );
              })}
            </ul>
          </div>
        )}
        {width > 992 && (
          <div className="nav-header">
            <button type="button" className="nav-toggle" onClick={toggleLinks}>
              <FaBars />
            </button>
            <Link to="/">
              <img src={logo_cables} alt="intech logo" />
            </Link>
          </div>
        )}
      </div>
    </NavContainer>
  );
};
const NavContainer = styled.nav`
  /*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v7.0.29,
* Autoprefixer: v9.7.6
* Browsers: last 4 version
*/

  //height: 5rem;
  display: block;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: var(--clr-blue1);
  .nav-center {
    width: 90vw;
    margin: 0 auto;
    max-width: var(--max-width);
    .nav-header {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      padding: 0.75rem;
      text-align: right;
      img {
        width: 70%;
      }
      .nav-toggle {
        background: transparent;
        border: transparent;
        color: var(--clr-white);
        cursor: pointer;
        -webkit-transition: var(--transition);
        -o-transition: var(--transition);
        transition: var(--transition);
        svg {
          font-size: 2rem;
        }
        &:hover {
          color: var(--clr-grey-6);
        }
      }
    }
    .links-container {
      height: 0;
      -webkit-transition: var(--transition);
      -o-transition: var(--transition);
      transition: var(--transition);
      overflow: hidden;
      .nav-links {
        a {
          color: var(--clr-grey-10);
          display: block;
          padding: 0.5rem 0.3rem;
          -webkit-transition: var(--transition);
          -o-transition: var(--transition);
          transition: var(--transition);
          // text-transform: capitalize;
          letter-spacing: 0.05rem;
        }
      }
    }
    .show-container {
      height: auto;
    }
  }

  @media (min-width: 992px) {
    .nav-toggle {
      display: none;
    }
    .nav-center {
      display: -ms-grid;
      display: grid;
      -ms-grid-columns: auto 1fr auto;
      grid-template-columns: auto 1fr auto;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      padding: 1rem;
      .nav-header {
        padding: 0;
      }
      .links-container {
        margin-left: auto !important;
        height: auto !important;
        margin-top: 0.75rem;
        .nav-links {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;
          li {
            margin: 0 0.5rem;
            .activeLink {
              border-bottom: 2px solid var(--clr-green1);
            }
          }
          a {
            font-size: 1.1rem;

            &:hover {
              border-bottom: 2px solid var(--clr-green1);
              text-decoration: none;
            }
          }
        }
      }
    }
  }
`;
export default Navbar;
