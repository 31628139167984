import React, { useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { HashRouter } from "react-router-dom";

import { HomePage, WorksPage, ContactPage, ErrorPage } from "./pages";
import { FloatingButtons, Footer } from "./components";

function App() {
  const [showAlert, setShowAlert] = useState(true);
  return (
    <HashRouter>
      <FloatingButtons />
      <Switch>
        <Route exact path="/">
          <HomePage {...{ showAlert, setShowAlert }} />
        </Route>
        <Route exact path="/works">
          <WorksPage />
        </Route>
        <Route exact path="/contact">
          <ContactPage />
        </Route>
        <Route path="*">
          <ErrorPage />
        </Route>
      </Switch>
      <Footer />
    </HashRouter>
  );
}

export default App;
