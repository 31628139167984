import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { PageHeader } from "../components";
import { useLoadingCallback } from "../hooks/useLoadingCallback";
import { t_Clients } from "../utils/db";
import loadingSpinner from "../assets/Eclipse-1s-200px - loading.svg";

const Clients = () => {
  const [clients, setClients] = useState();

  const [fetchClients, isLoading, error, reset] = useLoadingCallback(
    async () => {
      const response = await fetch(
        `${process.env.REACT_APP_WS_BASE_URL}/table/${t_Clients}`
      );
      const jsonData = await response.json();
      setClients(jsonData);
    }
  );

  useEffect(() => {
    fetchClients();
  }, []);
  return (
    <Wrapper className="section" id="clients">
      <div className="section-center">
        <PageHeader
          title="Clientes"
          paragraph="Contamos con experiencia en el sector público y privado con empresas nacionales y multinacionales"
          dark={false}
        />
      </div>

      <Container error={error} isLoading={isLoading} clients={clients} />
    </Wrapper>
  );
};

const Container = (props) => {
  const { error, isLoading, clients } = props;
  if (error) {
    return (
      <span
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "70vh",
        }}
      >
        <h4 className="error-message-dark">{error.message}</h4>
      </span>
    );
  }
  if (isLoading || !clients) {
    return (
      <span
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "70vh",
        }}
      >
        <img src={loadingSpinner} draggable={false} />
      </span>
    );
  }
  return (
    <div className="client-list">
      {clients.map((client) => {
        return <Client key={client.cl_id} {...client} />;
      })}
    </div>
  );
};

const Client = (props) => {
  const { cl_extension, cl_name, cl_data } = props;
  return (
    <div className="client-item">
      <img
        src={`data:image/${cl_extension};base64,${cl_data}`}
        alt={cl_name}
        draggable={false}
      />
    </div>
  );
};

const Wrapper = styled.section`
  /*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v7.0.29,
* Autoprefixer: v9.7.6
* Browsers: last 4 version
*/

  min-height: 40rem;
  .client-list {
    display: -ms-grid;
    display: grid;
    gap: 1rem;
    margin: 1rem;
    -ms-grid-columns: 1fr 1rem 1fr;
    grid-template-columns: repeat(2, 1fr);

    .client-item {
      text-align: center;
      background-color: var(--clr-white);
      -webkit-box-shadow: var(--light-shadow);
      box-shadow: var(--light-shadow);
      height: 100%;
      min-height: 10rem;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      img {
        margin: 0;
        max-width: 100%;
        object-fit: contain;
        -o-object-fit: contain;
      }
    }
  }
  @media screen and (min-width: 640px) {
    .client-list {
      -ms-grid-columns: (1fr) [4];
      grid-template-columns: repeat(4, 1fr);
    }
  }
  @media screen and (min-width: 768px) {
    .client-list {
      margin: 1rem 35px;
      -ms-grid-columns: (1fr) [6];
      grid-template-columns: repeat(6, 1fr);
    }
  }
  /* .client-list {
    margin: 1rem;
    -ms-box-orient: horizontal;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -moz-flex;
    display: -webkit-flex;
    display: flex;
    //justify-content: center;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    .client-item {
      background-color: var(--clr-white);
      // background-color: var(--clr-green1);
      box-shadow: var(--light-shadow);
      justify-content: center;
      align-items: center;
      width: 50%;
      text-align: center;
      img {
        max-width: 100%;
        object-fit: contain;
        -o-object-fit: contain;
      }
    }
  } */
  /*   min-height: 40rem;
  .client-list {
    display: flex;
    justify-content: center;
    .col {
      margin-top: 0;
      height: auto;
      //max-height: 10rem;
      //display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      background-color: var(--clr-white);
      box-shadow: var(--light-shadow);
      //margin: 1rem 0 1rem 0;
      //padding: 0 1rem 0 1rem;
      //margin: 1rem;
      img {
        max-width: 100%;
        object-fit: contain;
        -o-object-fit: contain;
      }
    }
  }
  @media (min-width: 992px) {
    .client-list {
      .col-6 {
        margin: 1rem 1rem 1rem 1rem;
      }
    }
  } */
`;

export default Clients;
