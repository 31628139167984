export const FooterContact = [
  {
    id: "facebook",
    link: `https://www.facebook.com/${process.env.REACT_APP_SOCIAL_FACEBOOK}`,
    icon: "FaFacebook",
  },
  {
    id: "instagram",
    link: `https://www.instagram.com/${process.env.REACT_APP_SOCIAL_INSTAGRAM}`,
    icon: "FaInstagram",
  },
  {
    id: "email",
    link: "#",
    icon: "FiMail",
  },
  {
    id: "whatsapp",
    link: `https://wa.me/${process.env.REACT_APP_SOCIAL_WHATSAPP_CENTRAL}`,
    icon: "FaWhatsapp",
  },
  /* {
    id: "map-marker",
    link:
      "https://www.google.com/maps/place/INTECH+S.R.L./@-25.303609,-57.5520184,15z/data=!4m2!3m1!1s0x0:0x281730cc302716b1?sa=X&ved=2ahUKEwiW-IjxouHrAhWdGbkGHcGGAYQQ_BIwCnoECBYQCA",
    icon: "fa fa-map-marker",
  }, */
  {
    id: "linkedin",
    link: `https://www.linkedin.com/in/${process.env.REACT_APP_SOCIAL_LINKEDIN}/`,
    icon: "FaLinkedIn",
  },
];
