import { React, useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import { Navbar } from "../components";

const ErrorPage = () => {
  useEffect(() => {
    document.title = "Intech SRL - Error";
  });
  return (
    <>
      <Navbar />
      <Wrapper className="page-100">
        <section>
          <h1>404</h1>
          <h3>Lo sentimos, la página que está buscando no existe.</h3>
          <Link to="/" className="btn">
            Volver al Inicio
          </Link>
        </section>
      </Wrapper>
    </>
  );
};
const Wrapper = styled.main`
  /*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v7.0.29,
* Autoprefixer: v9.7.6
* Browsers: last 4 version
*/

  background: var(--clr-grey-10);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  h1 {
    font-size: 5rem;
    font-family: "Poppins", sans-serif;
  }
  h3 {
    text-transform: none;
    margin-bottom: 2rem;
  }
`;
export default ErrorPage;
