import React, { useState, useEffect } from "react";
import { t_Services } from "../utils/db";
import styled from "styled-components";

import { PageHeader, CarouselServices } from "../components";
import { useLoadingCallback } from "../hooks/useLoadingCallback";

import loadingSpinner from "../assets/Eclipse-1s-200px - loading.svg";

const Services = () => {
  const [services, setServices] = useState();

  const [fetchServices, isLoading, error, reset] = useLoadingCallback(
    async () => {
      const response = await fetch(
        `${process.env.REACT_APP_WS_BASE_URL}/table/${t_Services}`
      );
      const jsonData = await response.json();
      setServices(jsonData);
    }
  );

  useEffect(() => {
    fetchServices();
  }, []);
  return (
    <Wrapper className="section" id="services">
      <div className="section-center">
        <PageHeader
          title="Servicios"
          paragraph="Realizamos servicios en diferentes ámbitos de la industria electromecánica"
          dark={true}
        />
      </div>
      <Container error={error} isLoading={isLoading} services={services} />
    </Wrapper>
  );
};

const Container = (props) => {
  const { error, isLoading, services } = props;
  if (error) {
    return (
      <span
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "70vh",
        }}
      >
        <h4 className="error-message-dark">{error.message}</h4>
      </span>
    );
  }
  if (isLoading || !services) {
    return (
      <span
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "70vh",
        }}
      >
        <img
          src={loadingSpinner}
          style={{ marginTop: "3em" }}
          draggable={false}
        />
      </span>
    );
  }
  return <CarouselServices className="carousel-banners" services={services} />;
};
const Wrapper = styled.section`
  min-height: 40rem;
  // height: 25rem;
  padding: 1rem 0;
  text-align: -webkit-center;
  img {
  }

  --slider-height: 25rem;
  .controls-container {
    position: relative;
    width: 100%;
    height: var(--slider-height);
    //background-color: var(--clr-white);
    display: flex;
    align-items: center;

    button {
      position: absolute;
      z-index: 30;
      border: 0;
      padding: 0.15rem 0.05rem 0.35rem 0.05rem;
      font-weight: bold;
      font-size: 2rem;
      user-select: none;
      -webkit-user-select: none;
      background-color: var(--clr-grey-9);
      transition: var(--transition);
      &:hover {
        background-color: var(--clr-grey-8);
      }
    }
    .prev {
      left: 3rem;
    }
    .next {
      right: 3rem;
    }
  }
`;

export default Services;
