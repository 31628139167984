import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import { routeHomeImages } from "../utils/db";
import { useLoadingCallback } from "../hooks/useLoadingCallback";

import loadingSpinner from "../assets/Eclipse-1s-200px - loading.svg";
import AlertHome from "./AlertHome";

const Home = ({ showAlert, setShowAlert }) => {
  const [images, setImages] = useState();
  const [fetchImages, isLoading, error, reset] = useLoadingCallback(
    async () => {
      const response = await fetch(
        `${process.env.REACT_APP_WS_BASE_URL}/${routeHomeImages}`
      );
      const jsonData = await response.json();
      setImages(jsonData);
    }
  );

  useEffect(() => {
    fetchImages();
  }, []);
  return (
    <>
      <Wrapper className="section-center">
        <article className="content">
          <h1>
            Bienvenido a<br />
            Intech SRL
          </h1>
          <p>Su solución en trabajos electromecánicos</p>
          <Link to="/contact" className="btn hero-btn">
            Contáctenos
          </Link>
        </article>
        <article className={isLoading || error ? "" : "img-container"}>
          <Images error={error} isLoading={isLoading} images={images} />
        </article>
      </Wrapper>
      <AlertHome {...{ showAlert, setShowAlert }} />
    </>
  );
};

const Images = (props) => {
  const { error, isLoading, images } = props;
  if (error) {
    return <h4 className="error-message-light">{error.message}</h4>;
  }
  if (isLoading || !images) {
    return (
      <>
        <img src={loadingSpinner} draggable={false} />
      </>
    );
  }
  return (
    <>
      <img
        src={`data:image/${images[0].hi_extension};base64,${images[0].hi_data}`}
        alt="Front"
        className="main-img"
        draggable={false}
      />
      <img
        src={`data:image/${images[1].hi_extension};base64,${images[1].hi_data}`}
        alt="person working"
        className="accent-img"
        draggable={false}
      />
    </>
  );
};
const Wrapper = styled.section`
  min-height: 60vh;
  display: -ms-grid;
  display: grid;
  place-items: center;
  .img-container {
    display: none;
  }

  h1 {
    color: var(--clr-white);
    font-family: "Poppins", sans-serif;
    letter-spacing: 0;
  }

  p {
    line-height: 2;
    max-width: 45em;
    margin-bottom: 2rem;
    color: var(--clr-grey-8);
    font-size: 1rem;
  }
  .hero-btn {
    padding: 0.75rem 1.5rem;
    font-weight: 600;
  }
  @media (min-width: 992px) {
    height: calc(100vh - 5rem);
    grid-template-columns: 1fr 1fr;
    gap: 8rem;
    h1 {
      margin-bottom: 2rem;
    }
    p {
      font-size: 1.25rem;
    }
    .hero-btn {
      padding: 0.75rem 1.5rem;
      font-size: 1rem;
    }
    .img-container {
      display: block;
      position: relative;
    }
    .main-img {
      width: 100%;
      height: 450px;
      position: relative;
      border-radius: var(--radius);
      display: block;
      -o-object-fit: cover;
      object-fit: cover;
      z-index: 30;
    }
    .accent-img {
      position: absolute;
      bottom: 0;
      left: 0;
      height: 235px;
      // width: 310px;
      -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      transform: translateX(-50%);
      border-radius: var(--radius);
    }
    .img-container::before {
      content: "";
      position: absolute;
      width: 10%;
      height: 80%;
      background: var(--clr-green2);
      bottom: 0%;
      left: -8%;
      border-radius: var(--radius);
    }
  }
`;
export default Home;
