import { React, useEffect, useState } from "react";

import { t_Contact } from "../utils/db";

import { Navbar, PageHeader } from "../components";
import { FiPhoneCall, FiMail } from "react-icons/fi";
import { FaMapMarkerAlt } from "react-icons/fa";

import styled from "styled-components";
import { useLoadingCallback } from "../hooks/useLoadingCallback";

import loadingSpinner from "../assets/Eclipse-1s-200px - loading.svg";

const ContactPage = () => {
  const [offices, setOffices] = useState([]);

  const [fetchOffices, isLoading, error, reset] = useLoadingCallback(
    async () => {
      const response = await fetch(
        `${process.env.REACT_APP_WS_BASE_URL}/table/${t_Contact}`
      );
      const jsonData = await response.json();
      setOffices(jsonData);
    }
  );
  useEffect(() => {
    fetchOffices();
  }, []);
  useEffect(() => {
    document.title = "Intech SRL - Contacto";
  });
  return (
    <>
      <Navbar activeLink="Contacto" />
      <main style={{ backgroundColor: "var(--clr-blue1)" }}>
        <PageHeader
          title="Contáctenos"
          paragraph="Nos ponemos a su disposición para cualquier consulta."
          dark={true}
        />
        <Wrapper>
          <Container error={error} isLoading={isLoading} offices={offices} />
        </Wrapper>
      </main>
    </>
  );
};

const Container = (props) => {
  const { error, isLoading, offices } = props;
  if (error) {
    return (
      <span
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "70vh",
        }}
      >
        <h4 className="error-message-light">{error.message}</h4>
      </span>
    );
  }
  if (isLoading || !offices) {
    return (
      <span
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "70vh",
        }}
      >
        <img src={loadingSpinner} draggable={false} />
      </span>
    );
  }
  return (
    <>
      {offices.map((office) => {
        return (
          <div className="office" key={office.c_id}>
            <h4 className="office-title">{`Oficina en ${office.c_place}`}</h4>
            <div className="map">
              <iframe
                src={office.c_linkGoogleMaps}
                title={`map-${office.c_place}`}
              ></iframe>
            </div>
            <div className="contact-info">
              <div className="info">
                <div className="spec-info">
                  <div className="relativeInfo">
                    <div className="flex-auto-info">
                      <div className="icon">
                        <span>
                          <FiPhoneCall />
                        </span>
                      </div>
                      <h6>Teléfono</h6>
                      <p>
                        {JSON.parse(office.c_telephoneNumbers).map((tel) => {
                          return (
                            <span key={tel}>
                              {tel} <br />
                            </span>
                          );
                        })}
                      </p>
                    </div>
                    <div className="flex-auto-info">
                      <div className="icon">
                        <span>
                          <FaMapMarkerAlt />
                        </span>
                      </div>
                      <h6>Dirección</h6>
                      <p>{office.c_address}</p>
                    </div>
                    <div className="flex-auto-info">
                      <div className="icon">
                        <span>
                          <FiMail />
                        </span>
                      </div>
                      <h6>Email</h6>
                      <p>
                        {JSON.parse(office.c_emails).map((em) => {
                          return (
                            <span key={em}>
                              {em}
                              <br />
                            </span>
                          );
                        })}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};
const Wrapper = styled.section`
  width: 100%;
  min-height: 70vh;
  margin-left: auto;
  margin-right: auto;
  padding: 0 auto;
  padding-bottom: 50px !important;
  text-align: center;

  .office {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    min-height: 30rem;
    margin-top: 1.5rem;
    border-radius: 1%;
    .office-title {
      width: 100%;
      color: var(--clr-grey-10);
      letter-spacing: 0;
      font-family: "Poppins", sans-serif;
      font-size: 1.75rem;
    }
    .map {
      width: 83.333333%;
      height: 25rem;
      /* padding-left: 3rem;
      padding-right: 3rem; */
      background-color: var(--clr-grey-9);
      margin-left: auto;
      margin-right: auto;
      position: relative;
      iframe {
        border: 0;
        allow-fullscreen: "";
        aria-hidden: "false";
        tab-index: "0";
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
      }
    }
  }

  .contact-info {
    width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;

    .info {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;

      .spec-info {
        width: 100%;
        padding-left: 1rem;
        padding-right: 1rem;

        .relativeInfo {
          position: relative;
          margin-top: 1rem;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          -ms-flex-direction: column;
          flex-direction: column;
          .flex-auto-info {
            padding-top: 1.25rem;
            padding-bottom: 1.25rem;
            padding-left: 1rem;
            padding-right: 1rem;
            -webkit-box-flex: 0;
            -ms-flex: 0 1 auto;
            flex: 0 1 auto;
            width: 100%;
            .icon {
              width: 3rem;
              text-align: center;
              padding: 0.75rem;
              margin-bottom: 1.25rem;
              height: 3rem;
              -webkit-box-pack: center;
              -ms-flex-pack: center;
              justify-content: center;
              -webkit-box-align: center;
              -ms-flex-align: center;
              align-items: center;
              display: -webkit-inline-box;
              display: -ms-inline-flexbox;
              display: inline-flex;
              border-radius: 9999px;
              background-color: var(--clr-grey-9);
              color: var(--clr-green1);
              svg {
                font-size: 1.45rem;
              }
            }
            h6 {
              margin: 0;
              margin-bottom: 0.25rem;
              font-size: 1.75rem;
              font-weight: 600;
              color: var(--clr-grey-10);
            }
            p {
              margin: 0;
              display: block;
              -webkit-margin-before: 1em;
              margin-block-start: 1em;
              -webkit-margin-after: 1em;
              margin-block-end: 1em;
              -webkit-margin-start: 0px;
              margin-inline-start: 0px;
              -webkit-margin-end: 0px;
              margin-inline-end: 0px;
              font-weight: 600;
              color: var(--clr-grey-8);
              font-size: 1.25rem;
            }
          }
        }
      }
    }
  }
  @media (min-width: 640px) {
    max-width: 640px;
  }
  @media (min-width: 768px) {
    max-width: 768px;
    padding: 0 30px;

    .office {
      .map {
        width: 40%;
      }
      .office-title {
        //font-size: 2rem;
      }
      .contact-info {
        width: 60%;
        .spec-info {
          width: 100%;
          .relativeInfo {
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
            -ms-flex-direction: row;
            flex-direction: row;
            .flex-auto-info {
              width: 33.333333333%;
              h6 {
                font-size: 1.35rem;
              }
              p {
                font-size: 1.15rem;
              }
            }
          }
        }
      }
    }
  }

  @media (min-width: 1024px) {
    max-width: 1024px;
    .map {
      width: 33.33333333 %;
    }
  }
  @media (min-width: 1280px) {
    max-width: 1280px;
  }
`;

export default ContactPage;
