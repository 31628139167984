import { React, useEffect } from "react";

import { Home, Navbar, Services, About, Clients } from "../components";
//import    from "../";
const HomePage = ({ showAlert, setShowAlert }) => {
  useEffect(() => {
    document.title = "Intech SRL - Inicio";
  });
  return (
    <>
      <Navbar activeLink="Inicio" />
      <main style={{ backgroundColor: "var(--clr-blue1)" }}>
        <Home {...{ showAlert, setShowAlert }} />
      </main>
      <main style={{ backgroundColor: "var(--clr-grey-9)" }}>
        <About />
      </main>
      <main style={{ backgroundColor: "var(--clr-blue1)" }}>
        <Services />
      </main>
      <main style={{ backgroundColor: "var(--clr-grey-9)" }}>
        <Clients />
      </main>
    </>
  );
};

export default HomePage;
