import React from "react";
import styled from "styled-components";

import { FooterContact } from "../utils/FooterSocialData";

import {
  FaFacebook,
  FaInstagram,
  FaWhatsapp,
  FaLinkedinIn,
} from "react-icons/fa";
import { FiMail } from "react-icons/fi";

const Footer = () => {
  function getYear() {
    var today = new Date();
    var year = today.getFullYear();
    return year;
  }
  return (
    <Wrapper>
      <section className="ft-social">
        <ul className="ft-social-list">
          {FooterContact.map((social) => {
            return (
              <li key={social.id}>
                <a href={social.link} target="_blank" rel="noreferrer">
                  {social.id === "facebook" && <FaFacebook />}
                  {social.id === "instagram" && <FaInstagram />}
                  {social.id === "email" && <FiMail />}
                  {social.id === "whatsapp" && <FaWhatsapp />}
                  {social.id === "linkedin" && <FaLinkedinIn />}
                </a>
              </li>
            );
          })}
        </ul>
      </section>
      <section className="ft-legal">
        {/* &copy;  */}
        {getYear()} {/* Copyright */} Intech SRL
      </section>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  /*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v7.0.29,
* Autoprefixer: v9.7.6
* Browsers: last 4 version
*/

  background-color: var(--clr-blue1);
  line-height: 1.5;
  margin-bottom: 0;
  ul {
    list-style: none;
    padding-left: 0;
    a {
      text-decoration: none;
      color: var(--clr-grey-9);
      &:hover {
        color: var(--clr-grey-7);
      }
    }
  }
  .ft-social {
    padding: 0 1.875rem 1.25rem;
  }
  .ft-social-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding-top: 1.25rem;
  }
  .ft-social-list li {
    margin: 0.5rem;
    font-size: 1.25rem;
  }
  .ft-legal {
    padding: 0.9375rem 1.875rem;
    background-color: var(--clr-blue2);
    text-align: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    color: var(--clr-grey-5);
  }
`;

export default Footer;
