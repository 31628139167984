import React, { useState } from "react";
import { Alert } from "react-bootstrap";
import { Ri24HoursFill } from "react-icons/ri";

const AlertHome = ({ showAlert, setShowAlert }) => {
  return (
    <>
      {showAlert && (
        <Alert
          variant="primary"
          onClose={() => setShowAlert(false)}
          dismissible
          className="alert"
        >
          <div
            style={{
              display: "flex",
              flexWrap: "nowrap",
              justifyContent: "space-evenly",
              alignItems: "center",
            }}
          >
            <span
              style={{
                color: "var(--clr-green2)",
              }}
            >
              <Ri24HoursFill style={{ fontSize: "3em" }} />
            </span>
            <p style={{ marginBottom: 0, marginLeft: "2em" }}>
              Ofrecemos Servicios de Emergencia las 24 horas
            </p>
          </div>
        </Alert>
      )}
    </>
  );
};

export default AlertHome;
